import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import Lockimage from "../../../assets/images/lock.png";
import eyeImage from "../../../assets/images/eye.png";
import TextFieldComponent from "../../TextFieldComponent";
import { validateStepTwo } from "../../../utils/Validation";
import { resetStepperData } from "../../../redux/slices/auth/signupSlice";
import { walmartAccount } from "../../../redux/thunk/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { IoEye, IoEyeOff } from "react-icons/io5";

const Step2 = (props) => {
  const [toggleEye, setToggleEye] = React.useState(false);
  const [toggleId, setToggleId] = React.useState(false);
  const { buttonref, stepperData, setStepperData, errors, setErrors } = props;
  // const accStepper = useSelector((state) => state.root.signup);
  const { accStepper, timeStampForStore } = useSelector(
    (state) => state.root.signup,
  );
  const dispatch = useDispatch();
  const BulletListItem = styled("li")({
    display: "list-item",
    marginBottom: "8px",
    fontWeight: 400,
    fontSize: "12px",
  });

  const BulletList = styled("ul")({
    listStyleType: "disc",
    paddingLeft: "35px",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStepperData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear the error for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (accStepper?.success) {
      buttonref.current.click();
    } else if (timeStampForStore) {
      const storedTime = new Date(timeStampForStore).getTime();
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - storedTime) / (1000 * 60 * 60); // Time in hours
      if (elapsedTime < 72) {
        buttonref.current.click();
      }
    }
  }, [accStepper, timeStampForStore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateStepTwo(stepperData);
    if (isValid) {
      let req = {
        apiData: { ...stepperData, name: stepperData.name },
        resetState: resetStepperData,
      };
      dispatch(walmartAccount(req));
      // buttonref.current.click();
    }
    if (!isValid) {
      setErrors(errors);
    }
  };

  const toggleHandler = () => {
    setToggleEye(!toggleEye);
  };
  const toggleHandlerId = () => {
    setToggleId(!toggleId);
  };
  return (
    <>
      <Typography
        variant="headingXS"
        align="center"
        component="h3"
        sx={{ letterSpacing: "normal" }}
      >
        Create API Keys
      </Typography>
      <Typography variant="bodyXS" align="center" component="p" sx={{ mb: 3 }}>
        This information is visible in your Walmart account
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="bodyL"
                component="h5"
                sx={{
                  fontWeight: 600,
                  mb: 3,
                }}
              >
                Create Refund Stacker API Keys
              </Typography>
              {/* Descirption Start For left Section*/}
              <div className="apiPointList">
                <div className="thumb">1.</div>
                <div className="content">
                  <Typography>
                    Go to{" "}
                    <a
                      className="text-info"
                      style={{ textDecoration: "none" }}
                      href="https://developer.walmart.com/v1/login?clientType=seller"
                      target="blank"
                    >
                      https://developer.walmart.com/v1/login?clientType=seller
                    </a>{" "}
                    and login to an{" "}
                    <span style={{ fontWeight: "600" }}>Admin</span> account.
                  </Typography>
                </div>
              </div>
              <div className="apiPointList">
                <div className="thumb">2.</div>
                <div className="content">
                  <Typography>
                    Click{" "}
                    <span style={{ fontWeight: 600 }}>
                      Add New Key for a Solution Provider
                    </span>
                    , and select{" "}
                    <span style={{ fontWeight: 600 }}>Refund Stacker</span>.from
                    the dropdown, Click{" "}
                    <span style={{ fontWeight: 600 }}>Next</span>.
                  </Typography>
                </div>
              </div>
              <div className="apiPointList">
                <div className="thumb">3.</div>
                <div className="content">
                  <Typography>
                    On the{" "}
                    <span style={{ fontWeight: 600 }}>Permission Key</span>{" "}
                    page, select the necessary permission options and click{" "}
                    <span style={{ fontWeight: 600 }}>Submit</span>.
                  </Typography>
                </div>
              </div>

              <TableContainer component={Box} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: "100%" }} className="accessTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <img src={Lockimage} alt="lock" /> Full Access
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <img src={eyeImage} alt="lock" /> View Access
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <BulletList>
                          {["Growth"].map((text, index) => (
                            <BulletListItem
                              sx={{ fontSize: "14px" }}
                              key={index}
                            >
                              {text}
                            </BulletListItem>
                          ))}
                        </BulletList>
                      </TableCell>
                      <TableCell>
                        <BulletList>
                          {[
                            "Orders",
                            "Items",
                            "Returns",
                            "Account Profile",
                            "Reports",
                            "Fulfilment",
                            "Rules",
                          ].map((text, index) => (
                            <BulletListItem
                              sx={{ fontSize: "14px" }}
                              key={index}
                            >
                              {text}
                            </BulletListItem>
                          ))}
                        </BulletList>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="apiPointList">
                <div className="thumb">4.</div>
                <div className="content">
                  <Typography>
                    Once complete, a pop-up will indicate that the key has been
                    added. Find{" "}
                    <span style={{ fontWeight: 600 }}>Refund Stacker</span> on
                    the list of keys, unhide the{" "}
                    <span style={{ fontWeight: 600 }}>Client Secret</span>. Copy
                    these and paste it into Refund Stacker’s{" "}
                    <span style={{ fontWeight: 600 }}>
                      Walmart Client Secret{" "}
                    </span>
                    field.
                  </Typography>
                </div>
              </div>
              <Typography sx={{ mt: 4 }}>
                <a
                  target="blank"
                  href="https://drive.google.com/file/d/19BkDn-GAic6n4tID6qWWOvOVHt2yKaEK/view"
                  className="text-info"
                  style={{
                    textDecoration: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  For a more detailed set of instructions, click here.
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card
                style={{
                  boxShadow:
                    "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                }}
              >
                <CardContent>
                  <Typography
                    variant="bodyL"
                    component="h5"
                    sx={{
                      fontWeight: 600,
                      mb: 3,
                    }}
                  >
                    Walmart Store Details
                  </Typography>
                  <TextFieldComponent
                    id="name"
                    name="name"
                    label="Store name"
                    value={stepperData?.name}
                    onChange={handleInputChange}
                    error={errors?.name}
                  />
                  <TextFieldComponent
                    id="walmart_client_id"
                    name="walmart_client_id"
                    label="Client ID"
                    type={toggleId ? "text" : "password"}
                    value={stepperData?.walmart_client_id}
                    onChange={handleInputChange}
                    error={errors?.walmart_client_id}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={toggleHandlerId} edge="end">
                            {toggleId ? <IoEye /> : <IoEyeOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldComponent
                    id="walmart_client_secret"
                    name="walmart_client_secret"
                    label="Client Secret"
                    type={toggleEye ? "text" : "password"}
                    value={stepperData?.walmart_client_secret}
                    onChange={handleInputChange}
                    error={errors?.walmart_client_secret}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={toggleHandler} edge="end">
                            {toggleEye ? <IoEye /> : <IoEyeOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Step2;
