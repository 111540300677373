import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import { validateFormSubmit } from "../../../utils/Validation";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/thunk/auth/authThunk";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import Logo from "../../../assets/images/logo/logo.svg";
import { setUserData } from "../../../redux/slices/auth/signupSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [togglePassword, settogglePassword] = useState(false);
  const [formSubmit, setFormSubmit] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormSubmit({
      ...formSubmit,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error for the current field
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateFormSubmit(formSubmit);
    if (!isValid) {
      setErrors(errors);
    } else {
      let req = {
        apiData: {
          email: formSubmit?.email?.toLowerCase(),
          password: formSubmit?.password,
        },
        navigate: navigate,
      };
      dispatch(loginUser(req));
      dispatch(
        setUserData({
          email: formSubmit?.email?.toLowerCase(),
          password: formSubmit?.password,
        })
      );
    }
  };
  const toggleHandlerId = () => {
    settogglePassword(!togglePassword);
  };

  return (
    <>
      <div className="authPage SignIn">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <div className="authLeft">
              <div className="authTopContent">
                <div className="inner">
                  <Typography
                    variant="headingS"
                    className="text-primary"
                    component="h2"
                    sx={{ letterSpacing: "normal", mb: 0 }}
                  >
                    Save your time and money
                  </Typography>
                  <Typography
                    className="text-dark"
                    component="p"
                    sx={{ fontSize: "18px", mb: 0 }}
                  >
                    As experienced sellers ourselves, we know exactly what works
                    and what doesn’t. You only pay if we’re successful.
                  </Typography>
                </div>
              </div>
              <div className="authBottomContent"></div>
              <div className="authLogo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={8}>
                <div
                  className="authForm"
                  style={{
                    display: "flex",
                    padding: "0px 16px",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "90%",
                    gap: "16px",
                  }}
                >
                  <Box
                    className="logo"
                    sx={{ display: { sm: "block", md: "none" } }}
                  >
                    <img src={Logo} alt="Logo" />
                  </Box>
                  <div>
                    <Typography
                      variant="headingXS"
                      component="h3"
                      sx={{ letterSpacing: "normal" }}
                    >
                      Sign In to RefundStacker
                    </Typography>
                    <Typography
                      variant="bodyM"
                      component="p"
                      sx={{ letterSpacing: "normal", mb: 2 }}
                    >
                      New Here ?{" "}
                      <Link
                        variant="text"
                        underline="none"
                        onClick={() => navigate("/signup")}
                        sx={{ cursor: "pointer" }}
                      >
                        Create an Account
                      </Link>
                    </Typography>
                  </div>
                  <TextFieldComponent
                    id="email"
                    name="email"
                    value={formSubmit?.email}
                    label="Email"
                    type="email"
                    onChange={handleInputChange}
                    error={errors?.email}
                  />
                  <TextFieldComponent
                    id="password"
                    name="password"
                    label="Password"
                    type={togglePassword ? "text" : "password"}
                    value={formSubmit?.password}
                    onChange={handleInputChange}
                    error={errors?.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={toggleHandlerId} edge="end">
                            {togglePassword ? <IoEye /> : <IoEyeOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Link
                    variant="text"
                    underline="none"
                    onClick={() => navigate(`/password-reset`)}
                    sx={{ cursor: "pointer" }}
                  >
                    Reset Password
                  </Link>
                </div>
                <Box sx={{ pl: 2 }}>
                  By continuing, you agree to Refund Stacker’s{" "}
                  <Link
                    variant="text"
                    underline="none"
                    sx={{ cursor: "pointer" }}
                    target="_blank"
                    href="https://www.refundstacker.com/privacy-policy"
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    variant="text"
                    underline="none"
                    sx={{ cursor: "pointer" }}
                    target="_blank"
                    href="https://www.refundstacker.com/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;
